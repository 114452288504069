<template>
  <div class="d-none">
    <div v-if="type === 'right'">
      <b-dropdown variant="black" right toggle-class="header-item p-2" style="z-index:10000;">
        <template v-slot:button-content>
          <img v-if="language === 'pt'" class src="@/assets/images/flags/pt.svg" alt="Português" height="30" />
          <img v-else-if="language === 'es'" class src="@/assets/images/flags/es.svg" alt="Espanol" height="30" />
          <img v-else class src="@/assets/images/flags/us.svg" alt="English" height="30" />
        </template>
        <b-dropdown-item v-on:click="setLanguage('pt')">
          <img src="@/assets/images/flags/pt.svg" alt="Português" class="mr-2" height="20" />
          <span class="align-middle">Português</span>
        </b-dropdown-item>
        <b-dropdown-item v-on:click="setLanguage('en')">
          <img src="@/assets/images/flags/us.svg" alt="English" class="mr-2" height="20" />
          <span class="align-middle">English</span>
        </b-dropdown-item>
        <b-dropdown-item v-on:click="setLanguage('es')">
          <img src="@/assets/images/flags/es.svg" alt="Espanol" class="mr-2" height="20" />
          <span class="align-middle">Espanol</span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div v-else>
      <b-dropdown variant="black" dropup toggle-class="header-item p-2" style="z-index:10000;">
        <template v-slot:button-content>
          <img v-if="language === 'pt'" class src="@/assets/images/flags/pt.svg" alt="Português" height="30" />
          <img v-else-if="language === 'es'" class src="@/assets/images/flags/es.svg" alt="Espanol" height="30" />
          <img v-else class src="@/assets/images/flags/us.svg" alt="English" height="30" />
        </template>
        <b-dropdown-item v-on:click="setLanguage('pt')">
          <img src="@/assets/images/flags/pt.svg" alt="Português" class="mr-2" height="20" />
          <span class="align-middle">Português</span>
        </b-dropdown-item>
        <b-dropdown-item v-on:click="setLanguage('en')">
          <img src="@/assets/images/flags/us.svg" alt="English" class="mr-2" height="20" />
          <span class="align-middle">English</span>
        </b-dropdown-item>
        <b-dropdown-item v-on:click="setLanguage('es')">
          <img src="@/assets/images/flags/es.svg" alt="Espanol" class="mr-2" height="20" />
          <span class="align-middle">Espanol</span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      language: 'us',
    }
  },
  props: {
    type: {
      default: 'right'
    },
  },
  methods: {
    setLanguage(lang) {
      localStorage.language = lang
      this.language = lang
      this.$translate.setLang(lang)
    }
  },
  mounted() {
    if (localStorage.language) {
      this.language = localStorage.language
      this.$translate.setLang(localStorage.language)
    } else {
      this.setLanguage('us')
    }
  }
};
</script>